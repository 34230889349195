<template>
  <div class="wrap">
    <quill-editor v-model="details" @change="changeValue" ref="myQuillEditor" class="ql-editor editer" :options="editorOption" @ready="onEditorReady($event)">
    </quill-editor>
    <el-upload :before-upload="beforeUpload" :headers="{token: TOKEN}" class="upload-demo" name="file" :action="qnLocation" :data="uploadData" :on-success='upScuccess' ref="upload" style="display:none">
      <el-button size="small" type="primary" :id="id" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="插入中,请稍候">点击上传</el-button>
    </el-upload>
  </div>
</template>
<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import mixin from '@/mixins/dialogMixin'
import * as Quill from 'quill'
import { mapState } from 'vuex'

export default {
  components: { quillEditor },
  mixins: [mixin],
  // model: {
  //   prop: 'detail',
  //   event: 'change',
  // },
  props: {
    detail: { // 绑定值
      required: true,
      type: String,
    },
    qnLocation: {
      required: true,
      type: String,
    },
    id: { // 页面多次引用的时候要记得加id，不然id作为唯一值会报错
      type: String,
      default: 'imgInput',
    },
    /**
     * 是否显示插入腾讯视频的按钮
     */
    pasteTencentVideo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["TOKEN"]),
  },
  data() {
    const bar = ['image'];
    if (this.pasteTencentVideo) {
      bar.push('video');
    }

    return {
      editorOption: {
        placeholder: '请输入...',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            // ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }], // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1 ' }], // outdent/indent
            [{ 'direction': 'rtl' }], // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
            // [{ 'font': [] }],
            [{ 'align': [] }],
            bar, //去除video即可
          ],
        },
      },
      details: this.detail || "", // 传值
      uploadData: {},
      fullscreenLoading: false,
      addRange: [],
      uploadType: '',
    }
  },
  watch: {
    detail() {
      this.details = this.detail
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.myQuillEditor.quill.getModule('toolbar').addHandler('image', this.imgHandler)

      this.$refs.myQuillEditor.quill.getModule('toolbar').addHandler('video', this.videoHandler)
    })
  },
  methods: {
    changeValue() {
      this.$emit('change', this.details)
    },
    onEditorReady(editor) {
      console.log(editor)
    },
    beforeUpload(file) {
      if (file.size < 10 * 1024 * 1024) {
        return true
      }
      this.$message.error('图片大小超出限制！')
      return false
    },
    // 图片上传成功回调   插入到编辑器中
    upScuccess(e) {
      this.fullscreenLoading = false
      const vm = this
      let url = ''
      if (this.uploadType === 'image') { // 获得文件上传后的URL地址
        url = e.data.url
      }
      if (url != null && url) { // 将文件上传后的URL地址插入到编辑器文本中
        const value = url
        vm.addRange = vm.$refs.myQuillEditor.quill.getSelection()
        vm.$refs.myQuillEditor.quill.insertEmbed(vm.addRange !== null ? vm.addRange.index : 0, vm.uploadType, value, Quill.sources.USER) // 调用编辑器的 insertEmbed 方法，插入URL
      } else {
        this.$message.error(`${vm.uploadType}插入失败`)
      }
      this.$refs['upload'].clearFiles() // 插入成功后清除input的内容
      console.log(vm.$refs.myQuillEditor.quill)
    },
    // 点击图片ICON触发事件
    imgHandler(state) {
      this.addRange = this.$refs.myQuillEditor.quill.getSelection()
      if (state) {
        const fileInput = document.getElementById(this.id)
        fileInput.click() // 加一个触发事件
      }
      this.uploadType = 'image'
    },

    videoHandler() {
        const vm = this
        this.$prompt('请粘贴腾讯视频代码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          //inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          //inputErrorMessage: '邮箱格式不正确'
        }).then(({ value }) => {
          if (!value) {
            this.$message({
              type: 'error',
              message: '请粘贴代码',
            });
            return;
          }
          const arr = value.split(' ');
          let element = '';
          arr.forEach((e) => {
            if (e.indexOf("src=") > -1) {
              element = e;
            }
          })

          if (element === '') {
            return;
          }

          const str = element.replace(/^src=/g, '').replace(/['"]/g, '');

          console.log('instert', str);
          vm.$refs.myQuillEditor.quill.insertEmbed(vm.addRange !== null ? vm.addRange.index : 0, 'video', str, Quill.sources.USER) // 调用编辑器
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '取消输入'
          // });
        });
    },
  },
}
</script>
<style lang="less" scoped>
  @import url('../assets/styles/mixin.scoped.less');
  .ql-editor {
    .ql-editor {
      height: 188px !important;
    }
  }
</style>
